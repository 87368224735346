/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'export_more_actions_light': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="#F9F9F9" fill-rule="nonzero"><path pid="0" d="M14.783 2.278l-6.389 6.39a.5.5 0 01-.707-.708l6.389-6.389H9.283a.5.5 0 110-1h5.75a.75.75 0 01.75.75v5.75a.5.5 0 11-1 0V2.278z"/><path pid="1" d="M6.505 2.5a.5.5 0 010 1H3.5A1.5 1.5 0 002 5v7.998a1.5 1.5 0 001.5 1.5h8.005a1.5 1.5 0 001.5-1.5V10a.5.5 0 111 0v2.998a2.5 2.5 0 01-2.5 2.5H3.5a2.5 2.5 0 01-2.5-2.5V5a2.5 2.5 0 012.5-2.5h3.005z"/></g>'
  }
})
